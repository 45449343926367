export const UNBOND_DELEGATIONS_FETCH_SUCCESS = 'UNBOND_DELEGATIONS_FETCH_SUCCESS';
export const UNBOND_DELEGATIONS_FETCH_ERROR = 'UNBOND_DELEGATIONS_FETCH_ERROR';
export const UNBOND_DELEGATIONS_LIST = 'UNBOND_DELEGATIONS_LIST';
export const UNBOND_DELEGATIONS_FETCH_IN_PROGRESS = 'UNBOND_DELEGATIONS_FETCH_IN_PROGRESS';

export const TX_UNBOND_MEMO_SET = 'TX_UNBOND_MEMO_SET';
export const TX_UNBOND_AMOUNT_SET = 'TX_UNBOND_AMOUNT_SET';
export const TX_UNBOND_TO_ADDRESS_SET = 'TX_UNBOND_TO_ADDRESS_SET';
export const TX_UNBOND_MODAL_SHOW = 'TX_UNBOND_MODAL_SHOW';
export const TX_UNBOND_MODAL_HIDE = 'TX_UNBOND_MODAL_HIDE';
