import React from 'react';
import AddressModal from "./AddressModal";

const Address = () => {
    return (
        <>
            <AddressModal/>
        </>
    );
};

export default Address;
