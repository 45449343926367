import React from 'react';
import KeplrModal from "./KeplrModal";

const Keplr = () => {
    return (
        <>
            <KeplrModal/>
        </>
    );
};

export default Keplr;
