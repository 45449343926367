import React from 'react';
import ModalForm from "./ModalForm";
import ModalAddress from "./ModalAddress";

const KeyStore = () => {
    return (
        <>
            <ModalForm/>
            <ModalAddress/>
        </>
    );
};

export default KeyStore;
