export const lightTheme = {
    bodyBackground: '#c2c2c2',
    navigationBackground: '#c2c2c2',
    navItemActiveColor: '#ffc942',
    tableBackground: '#c2c2c2',
    tableBorderColor: '#E3E5F4',
    selectItemBorderColor: '#E3E5F4',
    activeTabTextColor: '#272931',
    activeTabBorderColor: '#272931',
    infoHeadingColor: '#212529',
    lineValueColor: '#2E3239',
    labelColor: '#2E3239',
    modalHeaderColor: '#313238',
    tableRowBorder: '#e0e0e0',
    paginationIconColor: '#757575',
    paginationDisabledIconColor: 'rgba(0, 0, 0, 0.26)',
    infoBoxBorder: '#EAEBF6',
    votingPowerColor: '#272931',
    inputBackground: '#F5F6FA',
    refreshButtonColor: '#6c757d',
    modalBorderColor: '#E3E5F4',
    backButtonBgColor: '#F0F1F2',
    profileDropDownBorder: '#EAEBF6',
    noteBackground: '#FEF5F6',
    noteFontColor: '#2E3239',
    copyIconColor: '#2E3239',
    feeBoxBackground: '#c2c2c2',
    selectIconColor: 'rgba(0, 0, 0, 0.54)'
};

export const darkTheme = {
    bodyBackground: '#1e1e1e',
    navigationBackground: '#1e1e1e',
    navItemActiveColor: '#ffc942',
    tableBackground: '#2c2c2c',
    tableBorderColor: '#9d9d9d',
    selectItemBorderColor: '#212529',
    activeTabTextColor: '#ffc942',
    activeTabBorderColor: '#ffc942',
    infoHeadingColor: '#8D9CB5',
    lineValueColor: '#9d9d9d',
    labelColor: '#8D9CB5',
    modalHeaderColor: '#ffc942',
    tableRowBorder: '#4244465c',
    paginationIconColor: '#e9ecef',
    paginationDisabledIconColor: '#e9ecef7d',
    infoBoxBorder: '#4244465c',
    votingPowerColor: '#94979b',
    inputBackground: '#2e3239',
    refreshButtonColor: '#8D9CB5',
    modalBorderColor: '#94979b52',
    backButtonBgColor: '#495057',
    profileDropDownBorder: '#4244465c',
    noteBackground: '#2e3239',
    noteFontColor: '#ffc942a1',
    copyIconColor: '#8D9CB5',
    feeBoxBackground: '#2e3239',
    selectIconColor: '#94979b'
};
